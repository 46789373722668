'use client';

import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material/';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import { isEqual } from 'lodash';

import { uniqueId } from '~/shared';
import type { CampaignSegment, SegmentFromList } from '~/types';

import { SegmentsSelect } from '../../../Segments';
import {
  StyledCampaignFormRow,
  StyledCampaignFormSubtitle,
} from '../../components/elements';
import { useGetCampaign, useUpdateCampaign } from '../../hooks';

const SEGMENTS = [
  { id: '1', name: 'Tipo de negócio', key: 'tipoNegocio' },
  { id: '2', name: 'Estado', key: 'estado' },
  { id: '3', name: 'Departamento', key: 'departamentos' },
];

export function isSegmentEmpty(segments: CampaignSegment | undefined) {
  let isEmpty = !segments ? true : Object.keys(segments).length === 0;
  if (!isEmpty && segments) {
    isEmpty = Object.keys(segments).every(
      key => segments[key as keyof CampaignSegment].length === 0
    );
  }
  return isEmpty;
}

export function RowSegments({
  campaign,
  updateCampaignQuery,
}: {
  campaign: ReturnType<typeof useGetCampaign>['data'];
  updateCampaignQuery: ReturnType<typeof useUpdateCampaign>;
}) {
  const [segments, setSegments] = useState<SegmentFromList[]>([]);
  const [blockedSegments, setBlockedSegments] = useState<string[]>([]);

  function handleAddSegment(index: number) {
    setSegments(prevSegments => [
      ...prevSegments.slice(0, index + 1),
      { id: uniqueId() },
      ...prevSegments.slice(index + 1),
    ]);
  }

  function handleRemoveSegment(index: number) {
    const removedSegmentKey = segments[index].segmentType?.key;
    setSegments(state => state.filter((_, i) => i !== index));
    setBlockedSegments(state => state.filter(key => key !== removedSegmentKey));
  }

  useEffect(() => {
    const newBlockedSegments = segments
      .map(item => item.segmentType?.key)
      .filter(item => item !== undefined) as string[];

    setBlockedSegments(newBlockedSegments);
  }, [segments]);

  useEffect(() => {
    if (!campaign?.segments) return;

    if (segments.length === 0 && isSegmentEmpty(campaign.segments)) {
      setSegments([{ id: uniqueId() }]);
    }
    if (!isSegmentEmpty(campaign.segments) && segments.length === 0) {
      const segmentsFromCampaign = Object.keys(campaign.segments)
        .map(key => {
          const isEmpty =
            campaign.segments[key as keyof CampaignSegment].length === 0;
          if (isEmpty) return null;
          return {
            id: uniqueId(),
            segmentType: SEGMENTS.find(segment => segment.key === key) ?? null,
            segmentSelections:
              campaign.segments[key as keyof CampaignSegment] ?? null,
          };
        })
        .filter(item => item !== null) as SegmentFromList[];

      setSegments(segmentsFromCampaign);
    }
  }, [campaign?.segments, segments.length]);

  useDebounce(
    () => {
      if (!campaign?.segments) return;

      const allSegmentsFromCampaign =
        Object.keys(campaign.segments)?.flatMap(key => {
          return campaign.segments[key as keyof CampaignSegment].map(
            ({ id }) => ({ id })
          );
        }) ?? [];
      const allSegmentsSelectionIds = segments.flatMap(
        ({ segmentSelections }) => {
          return segmentSelections?.map(({ id }) => ({ id })) ?? [];
        }
      );
      if (!isEqual(allSegmentsFromCampaign, allSegmentsSelectionIds)) {
        updateCampaignQuery.mutate({
          id: campaign.id,
          segments: allSegmentsSelectionIds,
        });
      }
    },
    1000,
    [segments, campaign?.segments]
  );

  return (
    <>
      <StyledCampaignFormSubtitle>Segmentação</StyledCampaignFormSubtitle>

      {segments.map((segmentField, index) => (
        <StyledCampaignFormRow key={`segment-row-${segmentField.id}`}>
          <Autocomplete
            sx={{ flexGrow: 1, flexBasis: '30%' }}
            id={`segment-type_${segmentField.id}`}
            options={SEGMENTS}
            value={segmentField.segmentType ?? null}
            onChange={(_, newValue) => {
              setSegments(prevSegments => {
                const newSegments = [...prevSegments];
                newSegments[index].segmentType = newValue ?? undefined;
                newSegments[index].segmentSelections = [];
                return newSegments;
              });
            }}
            getOptionDisabled={option => blockedSegments.includes(option.key)}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                variant="filled"
                label="Tipo de segmentação"
              />
            )}
          />

          {!segmentField.segmentType && (
            <TextField
              label="Selecione uma segmentação"
              disabled
              fullWidth
              variant="filled"
            />
          )}

          {segmentField.segmentType && (
            <SegmentsSelect
              id={segmentField.segmentType.key}
              label={segmentField.segmentType.name}
              segment={segmentField.segmentType.key as keyof CampaignSegment}
              onChange={value => {
                setSegments(prevSegments => {
                  const newSegments = [...prevSegments];
                  newSegments[index].segmentSelections = value;
                  return newSegments;
                });
              }}
              value={segmentField.segmentSelections ?? []}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {segments.length <= 2 && (
              <Button
                variant="outlined"
                sx={{ borderRadius: 3, height: 50 }}
                onClick={() => handleAddSegment(index)}
              >
                E
              </Button>
            )}
            {segments.length > 1 && (
              <IconButton
                color="primary"
                onClick={() => handleRemoveSegment(index)}
                sx={{ width: 50, height: 50 }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </StyledCampaignFormRow>
      ))}
    </>
  );
}
